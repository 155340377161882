import React from 'react'

import { 
    AwardSection,
    AwardContainer,
    Heading2
} from './styles'

const Award = () => {
    return (
        <AwardSection>
            <AwardContainer>
                <Heading2>Home Muse: The Best Robotic Pool Cleaner In Australia For<br></br>2020</Heading2>
                <h3>“Best Overall Winner 2020”</h3>
                <p>Maytronics’ Dolphin is one of the most popular cleaners on the market; it is lightweight and ergonomic with moving parts that are robust and durable for long-term use.  With a Dolphin, escape into the things you love in your downtime instead of struggling with pool maintenance. The Maytroncis’ technology contained within your Dolphin will see you effectively clean the floor, walls and waterline of above ground and in-ground pools with crystal clear results without effort.</p>
                <p>The pool rover is light so moving it around, placing it in or removing it from the pool is easy. The multilayer net basket catches larger debris and ultra-fine filter baskets to deal with smaller dirt. This system improves navigation and cleaning capacity to give maximum pool coverage.</p>
            </AwardContainer>
        </AwardSection>
    )
}

export default Award
