import React from 'react'
import Energy from '../svg/energy'
import Water from '../svg/water'
import Chemicals from '../svg/chemicals'

import {
	BenefitsSection,
	BenefitsContainer,
	Heading2,
	BenefitsList,
	Video,
	VideoContainer,
	TextContainer,
} from './styles'

const Benefits = () => {
	return (
		<BenefitsSection>
			<BenefitsContainer>
				<Heading2>
					Australia’s first inground and above ground robotic pool cleaner to be
					Climate Care Certified
				</Heading2>
				<BenefitsList>
					<li>
						<Energy />
						<p>Reduce up to 83% of your energy consumption per year</p>
					</li>
					<li>
						<Water />
						<p>60-80% water wastage reduction from backwashes</p>
					</li>
					<li>
						<Chemicals />
						<p>Reduce the amount of chemicals released from backwashing</p>
					</li>
				</BenefitsList>

				<VideoContainer>
					<Video
						width="100%"
						height="100%"
						src="https://www.youtube.com/embed/6cyXeAtRcOU"
						allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
					></Video>
				</VideoContainer>

				<TextContainer>
					<p css={{ fontWeight: 'bold' }}>
						“It is a masterpiece of a robotic pool cleaner”
					</p>
					<p>RoboticPoolCleaners.Org</p>
					<p>
						“Honestly, this is one of the few robotic pool cleaners that is near
						perfect. It has no obvious weaknesses. The Dolphin Pool Cleaner is
						an efficient, powerful, easy to use robotic pool cleaner with a
						fantastic price. It is the pool cleaning equivalent of the BMW 3
						series – it does everything well.”
					</p>
				</TextContainer>
			</BenefitsContainer>
		</BenefitsSection>
	)
}

export default Benefits
