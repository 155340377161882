import React from 'react'
import PropTypes from 'prop-types'
const Chemicals = ({ size, color, ...otherProps }) => (
  <svg
    id="Chemicals"
    aria-hidden="true"
    focusable="false"
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    aria-label="Chemicals"
    width={size ? size : ''}
    height={size ? size: ''}
    {...otherProps}
    viewBox="0 0 191 191"
  >
    <title>Chemicals</title>
    <g transform="translate(-5.66 -4.98)"><circle cx="92" cy="92" r="92" transform="translate(5.66 4.98)" fill="#d4932a"/><path d="M125.356 52.794c5.345 0 5.345 8.112 0 8.112h-8.74v22.143c3.755 5.907 21.929 34.477 24.989 40.611 4.169 8.486-1.336 16.036-10.2 16.036h-66.5c-8.86 0-14.419-7.457-10.183-16.036.454-.708 21.969-35.8 25.016-40.611V60.906h-8.744c-5.345 0-5.345-8.112 0-8.112h12.8a4.076 4.076 0 014.009 4.009v27.422a4.129 4.129 0 01-.628 2.3c0 .08-21.916 34.237-25.163 40.772-1.336 2.86-.227 4.29 2.94 4.29h66.456c3.167 0 4.3-1.47 2.94-4.29-3.192-6.457-24.262-39.438-25.122-40.774a4.009 4.009 0 01-.722-2.3V56.856a4.009 4.009 0 014.009-4.009zm-44.514 52.04l-13.911 21.953h62.474l-12.027-19c-3.915 1.63-7.657 3.394-14.3 3.394-8.351-.002-14.311-6.337-22.236-6.347z" fill="#fff" fillRule="evenodd"/></g>
  </svg>
)
Chemicals.propTypes = {
  size: PropTypes.string,
  color: PropTypes.string,
}
Chemicals.defaultProps = {
  size: '',
  color: "#d4932a",
}
export default Chemicals
