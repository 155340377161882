import React from 'react'
import PropTypes from 'prop-types'
const LibertyLine = props => {
	const { color, size, viewBox, ...otherProps } = props
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			viewBox={viewBox}
			role="img"
			araia-label="Liberty Line"
			height={size}
			fill={color}
			{...otherProps}
		>
			<title>Liberty Line</title>
			<path
				fill="#ffffff"
				stroke="#58c8df"
				strokeWidth="1.75"
				d="M98.7,32.1V0.9H4.8C4.4,0.9,0.9,1,0.9,4.6v23.8c-0.1,2,1.5,3.7,3.5,3.7c0.1,0,0.2,0,0.4,0H98.7z"
			/>
			<path d="M97.1,0.1v32.8h65.2c0,0,3,0,3-4.5V4.6c0,0,0-4.5-3-4.5L97.1,0.1z" />
			<text
				css={{
					fontFamily: 'Magistral-Light, "Magistral"',
					fontSize: '27px',
					fontWeight: 300,
				}}
				transform="translate(11.5 26.5)"
			>
				Liberty
			</text>
			<text
				css={{
					fontFamily: 'Inter-Light,"Inter"',
					fontSize: '27px',
					fontWeight: 300,
				}}
				fill="#ffffff"
				transform="translate(105.443 27)"
			>
				Line
			</text>
		</svg>
	)
}
LibertyLine.propTypes = {
	viewBox: PropTypes.string,
	color: PropTypes.string,
	size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}
LibertyLine.defaultProps = {
	viewBox: '0 0 190 37',
	color: '#58c8df',
	size: '24',
}
export default LibertyLine
