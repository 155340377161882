import React from 'react'
import PropTypes from 'prop-types'
const MLine = props => {
	const { color, size, ...otherProps } = props
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 106.7 33"
			role="img"
			araia-label="M Line"
			height={size}
			fill={color}
			{...otherProps}
		>
			<title>M Line</title>
			<path
				fill="#ffffff"
				stroke="#58c8df"
				strokeWidth="1.75"
				d="M39.89,32.1V.88H4.77C4.38.88.88,1,.88,4.58V28.39A3.62,3.62,0,0,0,4.77,32.1Z"
			/>
			<path d="M38.43.08V32.89h65.22s3,0,3-4.5V4.58s0-4.5-3-4.5Z" />
			<text
				css={{
					fontFamily: 'Magistral-Light, "Magistral"',
					fontSize: '27px',
					fontWeight: 300,
				}}
				transform="translate(7.5 26.5)"
			>
				M
			</text>
			<text
				css={{
					fontFamily: 'Inter-Light,"Inter"',
					fontSize: '27px',
					fontWeight: 300,
				}}
				fill="#ffffff"
				transform="translate(46.74 27)"
			>
				Line
			</text>
		</svg>
	)
}
MLine.propTypes = {
	color: PropTypes.string,
	size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}
MLine.defaultProps = {
	color: '#58c8df',
	size: '24',
}
export default MLine
