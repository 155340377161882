import React from 'react'
import Table from './table'
import { Link } from 'gatsby'

import {
	RobotsSection,
	RobotsContainer,
	RobotHeading,
	RobotCategory,
	RobotClass,
	TableText,
	Tables,
	TableButton,
} from './styles'

const Robots = () => {
	const residential = [
		{
			class: 'Premium Class',
			classData: [
				{
					edges: [
						{
							model: 'M 600',
							slug: 'dolphin-m-600',
							coverage: 'Floors, walls & waterline for pools up to 15m',
							features: [
								'Dual-drive PowerStream mobility system ',
								'MyDolphin™ Plus mobile app ',
								'Filter Indicator',
								'Top load filter access',
								'Pick me up',
							],
						},
					],
					fieldValue: 'M Line',
				},
				{
					edges: [
						{
							model: 'Liberty 400',
							slug: 'dolphin-liberty-400',
							coverage: 'Floors, walls & waterline for pools up to 10 x 5 metres',
							features: [
								'Climate Care Certified',
								'CleverClean™ Coverage - Precise Navigation System',
								'Inductive Charging',
								'Cycle Selector',
								'Eco Mode',
								'Pick-up Mode',
								'Click-up',
								'MyDolphin™ Plus mobile app',
							],
						},
					],
					fieldValue: 'Liberty Line',
				},
			],
		},
		{
			class: 'Advanced Class',
			classData: [
				{
					edges: [
						{
							model: 'M 400 WB',
							slug: 'dolphin-m-400-wb',
							coverage: 'Floors, walls & waterline for pools up to 15m',
							features: [
								'Weekly timer',
								'MyDolphin™ Plus mobile app',
								'Interchangeable set of filters ',
								'Active cleaning',
								'Swivel - Cable Tangle Prevention System',
							],
						},
					],
					fieldValue: 'M Line',
				},
				{
					edges: [
						{
							model: 'S 400',
							slug: 'dolphin-s-400',
							coverage: 'Floors, walls & waterline for pools up to 15m',
							features: [
								'Climate Care Certified',
								'CleverClean™ Coverage - Precise Navigation System',
								'PowerStream mobility system',
								'Pick-up Mode',
								'Always Connected',
								'Filter Indicator',
								'MyDolphin™ Plus mobile app',
							],
						},
					],
					fieldValue: 'S Line',
				},
			],
		},
		{
			class: 'Classic Class',
			classData: [
				{
					edges: [
						{
							model: 'S 250',
							slug: 'dolphin-s-250',
							coverage: 'Floors, walls & waterline for pools up to 15m',
							features: [
								'Climate Care Certified',
								'MyDolphin™ Plus mobile app',
								'Always Connected',
								'PowerStream Mobility System',
								'CleverClean™ Coverage - Precise Navigation System',
							],
						},
					],
					fieldValue: 'S Line',
				},
			],
		},
		{
			class: 'Basic Class',
			classData: [
				{
					edges: [
						{
							model: 'S 150',
							slug: 'dolphin-s-150',
							coverage: 'Floors, walls & waterline for pools up to 10m',
							features: [
								'Climate Care Certified',
								'PowerStream mobility system',
								'CleverClean™ Coverage - Precise Navigation System',
							],
						},
					],
					fieldValue: 'S Line',
				},
				{
					edges: [
						{
							model: 'S 100',
							slug: 'dolphin-s-100',
							coverage: 'Floors, walls & waterline for pools up to 10m',
							features: [
								'CleverClean™ scanning system',
								'Interchangeable set of filters',
								'Active cleaning',
								'Waterline scrubbing',
								'Lightweight',
							],
						},
					],
					fieldValue: 'S Line',
				},
			],
		},
	]

	const commercial = [
		{
			class: 'Olympic size - 60m x 25m',
			classData: [
				{
					edges: [
						{
							model: 'Wave 300',
							slug: 'dolphin-wave-300-xl-50m',
							coverage: 'Up to 25-60m; floor only',
							features: [
								'Line-by-line cleaning process-state-of-the-art',
								'AutoDirect',
								'Multi pattern scanning',
								'Multipurpose Caddy with Auto Cable Release',
								'Swivel - cable tangle prevention system',
								'Crystal-clear filtration system',
								'MMI - interactive digital interface panel ',
							],
						},
					],
					fieldValue: 'Wave',
				},
			],
		},
		{
			class: 'Large size - 40m x 20m',
			classData: [
				{
					edges: [
						{
							model: 'Wave 150',
							slug: 'dolphin-wave-150',
							coverage: 'Up to 30m depth; floor area only',
							features: [
								'Remote control included',
								'Durable Tracks and Dual Scrubbing Brushes',
								'Caddy',
							],
						},
						{
							model: 'Wave 200',
							slug: 'dolphin-wave-200-xl',
							coverage: 'Up to 33m depth; floor area only',
							features: [
								'Highly efficient clog-free filtration with spiral two layer system',
								'Line-by-line cleaning process-state-of-the-art',
								'AutoDirect',
								'Multi pattern scanning',
								'Multipurpose Caddy ',
								'MMI - control panel for programming ',
							],
						},
					],
					fieldValue: 'Wave',
				},
			],
		},
		{
			class: 'Mid size - 25m x 10m',
			classData: [
				{
					edges: [
						{
							model: 'Wave 150',
							slug: 'dolphin-wave-150',
							coverage: 'Up to 25m depth; floors, walls & waterline',
							features: [
								'Swivel - cable tangle prevention system',
								'Precision Navigation',
								'Dynamic Dual-motor',
								'Caddy',
							],
						},
					],
					fieldValue: 'Wave',
				},
			],
		},
		{
			class: 'Small to Mid size - 20m',
			classData: [
				{
					edges: [
						{
							model: 'Wave 75',
							slug: 'dolphin-wave-75-cb',
							coverage: 'Up to 30m depth; floors, walls & waterline',
							features: [
								'Swivel - cable tangle prevention system',
								'CleverClean™ Coverage - Precise Navigation',
								'Caddy',
							],
						},
					],
					fieldValue: 'Wave',
				},
			],
		},
		{
			class: 'Small - 18m',
			classData: [
				{
					edges: [
						{
							model: 'Wave 20',
							slug: 'dolphin-wave-20',
							coverage: 'Up to 20m depth; floor only',
							features: [
								'Swivel - cable tangle prevention system',
								'CleverClean™ Coverage - Precise Navigation ',
								'Dual drive-motor',
								'Remote control',
							],
						},
					],
					fieldValue: 'Wave',
				},
			],
		},
	]

	return (
		<RobotsSection>
			<RobotsContainer>
				<RobotHeading>
					Maytronics Dolphin robotic pool cleaners at a glance
				</RobotHeading>

				<Tables>
					<RobotCategory>Residential Pools</RobotCategory>
					<RobotClass>Premium Class</RobotClass>
					<TableText>
						These are the most intelligent inground and above ground pool
						cleaners Australia has to offer that combine Maytronics’
						industry-leading technology with the ultimate pool cleaning
						experience.
					</TableText>

					{residential.map((robot, i) => {
						if (robot.class === 'Premium Class') {
							return <Table key={i} tbldata={robot.classData} />
						}
					})}

					<RobotClass>Advanced Class</RobotClass>
					<TableText>
						The best-selling class of Maytronics robotic swimming pool cleaners
						worldwide. The Advanced Line is configurable, remote controllable,
						and it exemplifies our motto of “Exceptional Experience”.
					</TableText>
					{residential.map((robot, i) => {
						if (robot.class === 'Advanced Class') {
							return <Table key={i} tbldata={robot.classData} />
						}
					})}
					<RobotClass>Classic Class</RobotClass>
					<TableText>
						Our most economical line of swimming pool cleaners providing value
						driven performance
					</TableText>
					{residential.map((robot, i) => {
						if (robot.class === 'Classic Class') {
							return <Table key={i} tbldata={robot.classData} />
						}
					})}
					<RobotClass>Basic Class</RobotClass>
					<TableText>
						Our most economical line of cleaners providing value driven
						performance
					</TableText>
					{residential.map((robot, i) => {
						if (robot.class === 'Basic Class') {
							return <Table key={i} tbldata={robot.classData} />
						}
					})}

					<RobotCategory>Commercial Pools</RobotCategory>
					<RobotClass>Olympic size - 60m x 25m</RobotClass>
					<TableText>
						Get professional pool performance to meet the challenging
						requirements of large aquatic facilities, regardless of shape or
						type, including beach entry pools.
					</TableText>
					{commercial.map((robot, i) => {
						if (robot.class === 'Olympic size - 60m x 25m') {
							return <Table key={i} tbldata={robot.classData} />
						}
					})}
					<RobotClass>Large size - 40m x 20m</RobotClass>
					<TableText>
						Revolutionary institutional pool vacuum cleaner delivers
						long-lasting reliability. With fast and efficient operation, you can
						keep your pools open for business while minimising cleaning
						downtime.
					</TableText>
					{commercial.map((robot, i) => {
						if (robot.class === 'Large size - 40m x 20m') {
							return <Table key={i} tbldata={robot.classData} />
						}
					})}
					<RobotClass>Mid size - 25m x 10m</RobotClass>
					<TableText>
						Gain heavy-duty pool and pool water cleaning performance with
						exceptional reliability. Your pool is consistently clean and the
						water sparkling clear - with little effort on your part.
					</TableText>
					{commercial.map((robot, i) => {
						if (robot.class === 'Mid size - 25m x 10m') {
							return <Table key={i} tbldata={robot.classData} />
						}
					})}
					<RobotClass>Small to Mid size - 20m</RobotClass>
					<TableText>
						Highly efficient fully automated cleaning for hotel, campground and
						school pools with heavy-duty cleaning performance and robust
						reliability.
					</TableText>
					{commercial.map((robot, i) => {
						if (robot.class === 'Small to Mid size - 20m') {
							return <Table key={i} tbldata={robot.classData} />
						}
					})}
					<RobotClass>Small - 18m</RobotClass>
					<TableText>
						Advance to thorough and fast automated cleaning of shallow pools,
						with the only robotic pool cleaner that can work in water that is
						just 20 cm (8 in) deep.
					</TableText>
					{commercial.map((robot, i) => {
						if (robot.class === 'Small - 18m') {
							return <Table key={i} tbldata={robot.classData} />
						}
					})}
				</Tables>

				<Link to="/book-a-demo/">
					<TableButton>Book a demo</TableButton>
				</Link>
			</RobotsContainer>
		</RobotsSection>
	)
}

export default Robots
