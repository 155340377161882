import React from 'react'
import tw, { css, styled } from 'twin.macro'
import { Link } from 'gatsby'
import Mline from '../svg/m-line'
import Sline from '../svg/s-line'
import Liberty from '../svg/liberty-line'
import Wave from '../svg/wave'

import { TableComp } from './styles'

const Table = ({ tbldata }) => {
	const rowSpan = tbldata.map((x, i) => {
		return {
			len: x.edges.length,
			value: x.fieldValue,
		}
	})

	return (
		<div css={{ overflow: 'auto' }}>
			<TableComp>
				<thead>
					<tr>
						<th>Series</th>
						<th>Type</th>
						<th>Coverage</th>
						<th>Smart Features</th>
					</tr>
				</thead>

				<tbody>
					{tbldata.map((item, i) => {
						return item.edges.map((x, ind) => {
							return (
								<tr key={ind}>
									{ind <= 0 ? (
										<td rowSpan={rowSpan[i].len}>
											{rowSpan[i].value === 'M Line' ? (
												<Mline size="30" />
											) : rowSpan[i].value === 'Liberty Line' ? (
												<Liberty size="30" viewBox="0 0 165 37" />
											) : rowSpan[i].value === 'S Line' ? (
												<Sline size="30" />
											) : rowSpan[i].value === 'Wave' ? (
												<Wave size="26" />
											) : (
												''
											)}
										</td>
									) : null}
									<td className="type">
										<Link to={`/product/${x.slug}`}>{x.model}</Link>
									</td>
									<td>{x.coverage}</td>
									<td>
										<ul>
											{x.features.map((feature, i) => {
												return <li key={i}>{feature}</li>
											})}
										</ul>
									</td>
								</tr>
							)
						})
					})}
				</tbody>
			</TableComp>
		</div>
	)
}

export default Table
