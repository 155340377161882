import React from 'react'
import Layout from '../components/layout'
import Meta from '../components/meta'
import { graphql } from 'gatsby'
import filterLocale from '../components/utils/filter-locale'
import { BLOCKS } from '@contentful/rich-text-types'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import WhichRobot from '../components/above-ground/which-robot'
import Robots from '../components/above-ground/robots'
import Benefits from '../components/above-ground/benefits'
import Award from '../components/above-ground/award'
import SupportContact from '../components/home/support-contact'
import {
	BannerSection,
	Heading1,
	Heading2,
	SecondSectionText,
	SecondSection,
} from '../components/above-ground/styles'

const AboveGround = ({ data: { groundpool } }) => {
	const locale = 'en-AU'
	const [{ node }] = filterLocale(groundpool.edges, locale)

	const { seoTitle, seoKeywords, seoDescription, body } = node

	const options = {
		renderNode: {
			[BLOCKS.HEADING_2]: (node, children) => {
				return <Heading2>{children}</Heading2>
			},
			[BLOCKS.PARAGRAPH]: (node, children) => {
				return <SecondSectionText>{children}</SecondSectionText>
			},
		},
	}

	return (
		<Layout>
			<Meta
				title={seoTitle}
				keywords={seoKeywords}
				description={seoDescription}
			/>

			<BannerSection
				className="which-model"
				css={{
					backgroundImage:
						'url(https://i1.wp.com/mydolphinau-2.flywheelsites.com/wp-content/uploads/DolphinM500_FacingRight-scaled-1.png?ssl=1)',
				}}
			>
				<Heading1>
					Effortless Choice With Maytronics’ Inground &<br></br>Above Ground
					Pool Cleaner Options
				</Heading1>
			</BannerSection>

			<SecondSection>
				{documentToReactComponents(body.json, options)}
			</SecondSection>
			<WhichRobot />
			<Robots />
			<Benefits />
			<Award />
			<SupportContact locale="en-AU" />
		</Layout>
	)
}

export const groundPoolQuery = graphql`
	query groundPoolQuery {
		groundpool: allContentfulPage(
			filter: { contentful_id: { eq: "2o2Q1WlKFi8rVUDNyAiqoU" } }
		) {
			edges {
				node {
					seoTitle
					seoKeywords
					seoDescription
					slug
					node_locale
					body {
						json
					}
				}
			}
		}
	}
`

export default AboveGround
