import React from 'react'
import { Link } from 'gatsby'

import {
	WhichRobotSection,
	WhichRobotContainer,
	Heading2,
	WhichRobotParagraph,
	WhichRobotList,
	WhichRobotListItem,
	WhichRobotButton,
} from './styles'

const WhichRobot = () => {
	const listItem = [
		'Select your pool size',
		'Choose the pool shape which most suits your pool',
		'Select the pool surface',
		"Decide which pool cleaner you'd like to purchase!",
	]

	return (
		<WhichRobotSection>
			<WhichRobotContainer>
				<Heading2>Choosing the right robotic pool cleaner for you</Heading2>
				<WhichRobotParagraph>
					As each pool is different, each Dolphin is different too. Use our
					comparison tool to find the right robot for you. It’s so simple to be
					able to select the right Dolphin corded, battery powered or cordless
					automatic pool cleaner.
				</WhichRobotParagraph>

				<WhichRobotList>
					{listItem.map((item, i) => (
						<WhichRobotListItem key={i}>{`${i +
							1}. ${item}`}</WhichRobotListItem>
					))}
				</WhichRobotList>

				<Link to="/which-model/">
					<WhichRobotButton>Which Robot Is Right For Me?</WhichRobotButton>
				</Link>
			</WhichRobotContainer>
		</WhichRobotSection>
	)
}

export default WhichRobot
