import React from 'react'
import PropTypes from 'prop-types'
const Energy = ({ size, color, ...otherProps }) => (
  <svg
    id="Energy"
    aria-hidden="true"
    focusable="false"
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    aria-label="Energy"
    width={size ? size : ''}
    height={size ? size: ''}
    {...otherProps}
    viewBox="0 0 191 191"
  >
    <title>Energy</title>
    <g transform="translate(-7.23 -5.5)"><circle cx="95.5" cy="95.5" r="95.5" transform="translate(7.23 5.5)" fill="#d4932a"/><path d="M140.356 87.642l-54.228 80.32a3.493 3.493 0 01-2.872 1.532 3.769 3.769 0 01-1.3-.221 3.493 3.493 0 01-2.112-3.838l9.581-55.222H67.767a3.7 3.7 0 01-3.493-4.625l18.251-59.224a3.465 3.465 0 013.341-2.5h36.126a3.631 3.631 0 012.761 1.463 3.424 3.424 0 01.456 3.148l-11.428 33.742h23.58a3.493 3.493 0 013.465 3.507 3.438 3.438 0 01-.594 1.933z" fill="#fff"/></g>
  </svg>
)
Energy.propTypes = {
  size: PropTypes.string,
  color: PropTypes.string,
}
Energy.defaultProps = {
  size: '',
  color: "#d4932a",
}
export default Energy

